<template>
  <v-container class="mt-10">
    <v-col
      md="12"
      class="text-h3 text-center mb-4"
      :style="{ color: '#0c4f88' }"
    >
      Produk
    </v-col>
    <v-row>
      <v-col md="3">
        <template>
          <div>
            <p class="text--primary text-h5">Produk Kami</p>
            <p>
              Produk Penjaminan dari PT Orion Penjaminan Indonesia
              <!-- <b>Loan</b> & <b>Non Loan</b> -->
            </p>
          </div>
        </template>
        <div class="d-flex align-end justify-center" :style="{ height: '25%' }">
          <v-btn rounded outlined color="grey" dark @click="prevSlide">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
            rounded
            outlined
            class="ml-2"
            color="grey"
            dark
            @click="nextSlide"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col md="9">
        <VueSlickCarousel v-bind="settings" ref="carousel">
          <div v-for="(item, index) in carouselData" :key="index">
            <div>
              <v-card
                class="mx-2 mb-3 custom-card"
                @click="
                  ProductDetail(
                    item.web_product_src_url,
                    item.web_product_name,
                    item.web_product_detail
                  )
                "
              >
                <v-img
                  class="white--text align-end"
                  height="300px"
                  :src="
                    require('@/assets/products/' + item.web_product_src_url)
                  "
                >
                </v-img>
                <v-card-subtitle class="pl-0">
                  <!-- <v-chip class="ma-2" small>{{
                    item.web_product_cashloan
                  }}</v-chip> -->
                </v-card-subtitle>
                <v-card-text
                  class="pl-0 mb-5 text--primary"
                  :style="{ 'min-height': '80px' }"
                >
                  <div>{{ item.web_product_name }}</div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </VueSlickCarousel>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog.detail"
      width="900px"
      persistent
      style="border-radius: 20px"
    >
      <v-card>
        <v-card-actions style="height: 80px">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            outlined
            color="grey"
            dark
            @click="dialog.detail = false"
            class="mr-5"
          >
            X
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
        <v-progress-linear
          indeterminate
          v-if="loading.detail"
        ></v-progress-linear>
        <v-container>
          <v-row class="mx-2">
            <v-col
              cols="12"
              class="text-h4 text-center"
              style="align-self: center; color: #114e87"
            >
              {{ detail.name }}
            </v-col>
            <v-col cols="12" class="d-flex justify-center mb-4">
              <v-row>
                <!-- <v-col cols="12" sm="4" md="3" class="text-center">
                  <img
                    :src="
                      detail.img
                        ? require(`@/assets/products/${detail.img}`)
                        : require('@/assets/user-no-photo.png')
                    "
                    :alt="detail.img ? detail.img : 'Placeholder Image'"
                    :style="{ border: '1px solid #ccc' }"
                    height="250"
                    width="250"
                  /> -->
                <!-- </v-col> -->
                <v-col cols="12" class="d-flex align-center">
                  <div class="text-justify" v-html="detail.description"></div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "MyComponent",
  components: { VueSlickCarousel },
  data() {
    return {
      dialog: {
        detail: false,
      },
      loading: {
        detail: false,
      },
      detail: {
        name: "",
        img: "",
        description: "",
      },
      settings: {
        arrows: false, // Sembunyikan panah bawaan karena kita akan menambahkan tombol manual
        dots: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      carouselData: [], // Inisialisasi carouselData sebagai array kosong
    };
  },
  created() {
    // Setelah komponen dibuat, inisialisasi carouselData dengan data yang diperlukan
    this.carouselData = [
      {
        web_product_id: 1,
        web_product_name: "Penjaminan Kredit Multiguna",
        web_product_src_url: "kredit-multiguna.jpg",
        web_product_cashloan: "Loan",
        web_product_detail:
          "Jaminan Kredit yang diberikan Lembaga Keuangan ( Bank dan Non Bank ) kepada Nasabah untuk memenuhi berbagai kebutuhan dengan sumber pengembalian adalah penghasilan tetap/gaji Nasabah",
      },
      {
        web_product_id: 2,
        web_product_name: "Penjaminan Kredit Pemilikan Rumah/FLPP",
        web_product_src_url: "flpp.jpg",
        web_product_cashloan: "Loan",
        web_product_detail:
          "Jaminan Kredit yang diberikan Lembaga Keuangan ( Bank dan Non Bank ) kepada Nasabah untuk pemilikan rumah baik produk komersil Lembaga Keuangan ( Bank dan Non Bank ) maupun program FLPP",
      },
      {
        web_product_id: 3,
        web_product_name: "Penjaminan Kredit Mikro",
        web_product_src_url: "kredit-mikro.jpg",
        web_product_cashloan: "Non Loan",
        web_product_detail:
          "Jaminan Kredit yang diberikan Lembaga Keuangan ( Bank dan Non Bank ) kepada Nasabah untuk mendukung kelancaran kegiatan usaha bagi Pelaku Usaha Mikro baik kredit modal kerja/investasi. Plafond kredit sd 250 Juta atau sesuai ketentuan Lembaga Keuangan ( Bank dan Non Bank )",
      },
      {
        web_product_id: 4,
        web_product_name: "Penjaminan Kredit Umum",
        web_product_src_url: "penjaminan-umum.jpg",
        web_product_cashloan: "Non Loan",
        web_product_detail:
          "Jaminan Kredit yang diberikan Lembaga Keuangan ( Bank dan Non Bank ) kepada Nasabah untuk mendukung kelancaran kegiatan usaha bagi Pelaku Usaha dengan plafond diatas Kredit Mikro",
      },
      {
        web_product_id: 5,
        web_product_name:
          "Penjaminan Kredit Konstruksi/Pengadaan Barang & Jasa",
        web_product_src_url: "barangjasa.jpg",
        web_product_cashloan: "Non Loan",
        web_product_detail:
          "Jaminan Kredit yang diberikan Lembaga Keuangan ( Bank dan Non Bank ) kepada Nasabah dalam rangka modal kerja untuk menyelesaikan pekerjaan konstruksi/pengadaan barang atau jasa sesuai dengan kontrak dengan Pemberi Kerja",
      },
      {
        web_product_id: 6,
        web_product_name: "Penjaminan Suply Chain Financing",
        web_product_src_url: "suplychain.jpg",
        web_product_cashloan: "Non Loan",
        web_product_detail:
          "Jaminan Kredit yang diberikan Lembaga Keuangan ( Bank dan Non Bank ) kepada Nasabah dalam rangka pengalihan penyelesaian piutang atau tagihan dari Nasabah kepada Lembaga Keuangan ( Bank dan Non Bank ) yang kemudian menagih piutang tersebut kepada Pemberi Kerja",
      },
      {
        web_product_id: 7,
        web_product_name: "Surety Bond",
        web_product_src_url: "suretybond.jpg",
        web_product_cashloan: "Non Loan",
        web_product_detail:
          "Jaminan kepada Obligee atas risiko kegagalan/wanprestasi Principal dalam melaksanakan pekerjaan sesuai yang diperjanjikan dengan Obligee",
      },
      {
        web_product_id: 8,
        web_product_name: "Kontra Bank Garansi",
        web_product_src_url: "bankgaransi.jpg",
        web_product_cashloan: "Non Loan",
        web_product_detail:
          "Jaminan sebagai kontra garansi atas fasilitas Bank Garansi yang diterbitkan oleh Bank kepada Terjamin",
      },
      {
        web_product_id: 9,
        web_product_name: "Customs Bond",
        web_product_src_url: "custombond.jpg",
        web_product_cashloan: "Non Loan",
        web_product_detail:
          "Jaminan atas fasilitas kepabeanan yang diberikan oleh Obligee/Dirjen Bea Cukai untuk menjamin risiko Principal apabila tidak dapat memenuhi kewajibannya dalam pungutan negara dalam rangka kepabeanan",
      },
    ];
  },
  methods: {
    prevSlide() {
      this.$refs.carousel.prev();
    },
    nextSlide() {
      this.$refs.carousel.next();
    },
    ProductDetail(image, name, desc) {
      this.dialog.detail = true;
      this.detail.img = image;
      this.detail.name = name;
      this.detail.description = desc;
    },
  },
};
</script>

<style scoped>
.custom-card {
  padding: 0px 22px; /* Sesuaikan padding sesuai kebutuhan Anda */
}
</style>
